<template>
    <popup-dialog-base
        title='Mitarbeiter-Bestätigung'
        ref="editorDialog"
        :toolButtons="buttons"
    >
        <template v-slot:content>
            <v-container full-width v-if="!edit">
                <v-row>
                    <v-col>
                        <v-select
                            :items="emailVariables.status.options"
                            @change="updateEmailBody()"
                            class="mb-0"
                            dense
                            solo
                            hide-details
                            :label="emailVariables.status.key" 
                            v-model="emailVariables.status.value"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="emailVariables.status.value === 'Bestätigt'">
                    <v-col
                        cols="12"
                        sm="8" md="8" ref="editorCol">
                        <!-- <h3>Email</h3>
                        <v-divider class="mb-2"></v-divider> -->
                        <v-text-field class="mb-2" hide-details dense solo v-model="betreff"></v-text-field>

                        <interface-text-editor
                            v-if="$refs.editorDialog !== undefined && requestedPersonData !== undefined"
                            v-model="email"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="4"
                    >
                        <!-- <h3>Mitarbeiter-Daten aus Datenbank</h3>
                        <v-divider class="mb-2"></v-divider> -->
                        <v-form>
                            <div v-for="(prop, key) in emailVariables" :key="key">
                                <div v-if="key !== 'status'">
                                    <v-select
                                        v-if="typeof prop.value === 'string' && prop.options"
                                        @change="updateEmailBody()"
                                        dense
                                        outlined
                                        hide-details
                                        :disabled="prop.disabled"
                                        :items="prop.options"
                                        :label="emailVariables[key].key" v-model="emailVariables[key].value">
                                    </v-select>
                                    <v-text-field
                                        v-else-if="typeof prop.value === 'string' && prop.key"
                                        @change="updateEmailBody()"
                                        class="mb-2"
                                        dense
                                        outlined
                                        hide-details
                                        :disabled="prop.disabled"
                                        :label="emailVariables[key].key" v-model="emailVariables[key].value">
                                    </v-text-field>
                                    <v-checkbox
                                        @change="updateEmailBody()"
                                        class="mb-2"
                                        dense
                                        hide-details
                                        :disabled="prop.disabled"
                                        :label="emailVariables[key].key"
                                        v-if="typeof prop.value === 'boolean' && prop.key"
                                        v-model="emailVariables[key].value"
                                    >
                                    </v-checkbox>
                                </div>
                            </div>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col
                        cols="12"
                        ref="editorCol">
                        <!-- <h3>Email</h3>
                        <v-divider class="mb-2"></v-divider> -->
                        <v-text-field label="Betreff" class="mb-2" hide-details dense solo v-model="betreff"></v-text-field>
                        <interface-text-editor
                            v-model="email"
                        />
                    </v-col>

                </v-row>

                
                <!-- Attachments -->
                <v-row>
                    <v-col class="pb-0 pt-1">
                        <interface-attachment
                            v-if="attachments && emailVariables.status.value === 'Bestätigt'"
                            v-model="attachments"
                            :allowSonderUrlaub="true"
                            :showSonderUrlaub="emailVariables.statusSonderurlaub.value === 'Gewünscht'"
                            :emailPreview="true"
                            :collection="'mitarbeiter_bestaetigung'"
                            :postDeleteMethod="editTemplates"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-container full-width v-else-if="edit && emailBlocks">
                <v-row v-for="(emailBlock, key) in emailBlocks" :key="key">
                    <v-col v-if="emailBlock.type !=='integer'">
                        <h3>{{emailBlock.label}}</h3>
                        <!-- {{emailBlock.interface}} -->
                        <interface-text-editor
                            v-if="emailBlock.interface === 'input-rich-text-html'"
                            v-model="emailBlocks[key].value"
                        />
                        <v-text-field
                            v-else-if="emailBlock.interface === 'input'"
                            solo
                            hide-details
                            v-model="emailBlocks[key].value"
                        />
                        <v-combobox
                            v-else-if="emailBlock.interface === 'select-dropdown'"
                            @keydown="$event.target.blur()"
                            @keypress="$event.target.blur()"
                            @keyup="$event.target.blur()"
                            solo
                            hide-details
                            :items="app.emailAdresses"
                            v-model="emailBlocks[key].value"
                        />

                        <!-- Attachments -->
                        <div
                            v-else-if="emailBlock.interface === 'files'"
                        >
                            <interface-attachment
                                v-if="attachments"
                                v-model="attachments"
                                @onUpdate="editTemplates()"
                                :allowSonderUrlaub="true"
                                :collection="'mitarbeiter_bestaetigung'"
                                :postDeleteMethod="editTemplates"
                            />
                            <interface-file-upload 
                                :uploadFolder="'Mitarbeiter Bestätigung'"
                                :collection="'mitarbeiter_bestaetigung'"
                                :itemId="emailBlocks.id.value"
                                :preUploadMethod="()=>{$refs.editorDialog.setLoading(true)}"
                                :postUploadMethod="editTemplates"
                            />
                        </div>

                    </v-col>
                </v-row>
                
            </v-container>
            
            <error-dialog 
                ref="ErrorDialog"
            />
        </template>
        <template v-slot:toolbar>
        </template>

    </popup-dialog-base>
</template>

<script>
import dayjs from 'dayjs';
import PopupDialogBase from '../PopupDialogBase.vue';
import InterfaceFileUpload from '../interfaces/InterfaceFileUpload.vue';
import InterfaceAttachment from '../interfaces/InterfaceAttachment.vue';
import InterfaceTextEditor from '../interfaces/InterfaceTextEditor.vue';
import ErrorDialog from '../ErrorDialog.vue';

export default {
    components:{
        PopupDialogBase,
        InterfaceFileUpload,
        InterfaceAttachment,
        InterfaceTextEditor,
        ErrorDialog,
    },
    props:{
        peopleManager:Object,
    },
    inject:[
        "app"
    ],
    data(){
        return {
            buttons:[
                {label:"Abbrechen", color:"error", icon:"", method:this.hide, viewCondition:()=>{return !this.edit}, disabled:()=>{return this.sending}},
                {label:"Vorlagen bearbeiten", color:"primary", icon:"mdi-pencil", method:this.editTemplates, viewCondition:()=>{return !this.edit}},
                {label:"Email Senden", color:"primary", icon:"mdi-email-send", method:this.sendEmail, viewCondition:()=>{return !this.edit}, disabled:()=>{return this.sending || !this.emailVariables.status.value}},
                {label:"Zurück", color:"error", icon:"", method:()=>{this.edit=false}, viewCondition:()=>{return this.edit}},
                {label:"Vorlagen speichern", color:"primary", icon:"mdi-check", method:this.saveTemplates, viewCondition:()=>{return this.edit}, disabled:()=>{return this.sending}},
                
            ],
            sending:false,
            id:undefined,
            userId:-1,
            requestedPersonData:undefined,
            senderEmail:undefined,
            emailVariables:{
                status:{key:"Status",value:"", options:["Bestätigt","Warteliste","Absage","Abgemeldet"]},
                bearbeiter:{key:"Bearbeiter",value:""},
                bearbeiter_telefon:{key:"Bearbeiter Telefon",value:""},
                vorname:{key:"Vorname",value:""},
                nachname:{key:"Nachname",value:""},
                email:{key:"Email",value:""},
                bereich:{value:""},
                statusSonderurlaub:{key:"Sonderurlaub", value:"",options:["Nicht gewünscht", "Gewünscht"]},
                nachWunsch:{key:"Einsatzbereich nach Wunsch",value:true},
                basicsAbsolviert:{key:"Basics absolviert",value:false},
                mitarbeiterWochenende:{key:"Mitarbeiter Wochenende bestätigt",value:true},
            },
            betreff:"",
            email:"",
            maxEditorHeight:0,
            edit:false,
            emailBlocks:undefined,
            attachments:undefined,
            camp:undefined,
        }
    },
    methods:{
        async loadAttachments(attachments){
            // load email attachments
            this.attachments = []
            for(let attachment of attachments){
                
                if(attachment.directus_files_id){
                    let result = await this.$network.requestGet("/files/"+attachment.directus_files_id);
                    if (result.status === 200){
                        this.attachments.push({
                            id:attachment.id,
                            sonderurlaub:attachment.sonderurlaub,
                            data:result.data.data
                        });
                    }
                }else{
                    this.attachments.push({
                        id:attachment.id,
                        data:{id:undefined, filename_download:undefined}
                    })
                }
            }
        },
        async getEmbeddedAttachments(email){
            var el = document.createElement('div');
            el.innerHTML = email;
            var images = el.getElementsByTagName('img');
            var attachments = []
            for(let i=0; i<images.length; i++){
                let img = images[i];
                var path = img.src;
                var id = path.split("/")[path.split("/").length-1]
                let url = "/files/"+id;
                let result = await this.$network.requestGet(url);
                let filename = "";
                if(result.status === 200){
                    filename = result.data.data.filename_download;
                }
                attachments.push({
                    filename:filename,
                    path:path,
                    cid:filename
                })
                img.src = "cid:"+filename
            }
            return {html:el.innerHTML, attachments:attachments};
        },
        getAttachments(){
            let attachments = [];
            for(let attachment of this.attachments){
                let url = "./uploads/"+attachment.data.filename_disk;
                if((this.emailVariables.statusSonderurlaub.value === 'Gewünscht' && attachment.sonderurlaub)
                    || !attachment.sonderurlaub
                ){
                    attachments.push({
                        filename:attachment.data.filename_download,
                        path:url
                    });
                }
            }
            return attachments;
        },
        async getFilteredEmailBlocks(emailBlocks){
            let blocks = undefined         
            let result = await this.$network.requestGet("/fields/mitarbeiter_bestaetigung");
            if(result.status === 200){
                blocks = {};
                let fields = result.data.data.sort((a,b)=>{
                    return a.meta.sort - b.meta.sort;
                });
                for(let field of fields){
                    blocks[field.field] = {
                        value:emailBlocks[field.field],
                        interface:field.meta.interface,
                        label:"",
                        type:field.type,
                    };
                    
                    if(field.meta.translations){
                        for(let trans of field.meta.translations){
                            if(trans.language === "de-DE"){
                                blocks[field.field].label = trans.translation;
                            }
                        }
                    }
                }
            }
            return blocks
        },
        async editTemplates(){
            this.$refs.editorDialog.setLoading(true);
            this.edit = true;
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString()
            url += "&fields=mitarbeiter_bestaetigung.*,mitarbeiter_bestaetigung.anhang.*"
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                this.emailBlocks = await this.getFilteredEmailBlocks(campResult.data.data[0].mitarbeiter_bestaetigung);
            }
            await this.loadAttachments(this.emailBlocks.anhang.value);
            this.$refs.editorDialog.setLoading(false);
        },
        async saveTemplates(){
            this.edit = false;
            let url = "/items/mitarbeiter_bestaetigung/"+this.emailBlocks.id.value.toString();
            let payload = {}
            for(let key in this.emailBlocks){
                payload[key] = this.emailBlocks[key].value;
            }
            let result = await this.$network.requestPatch(url,payload);
            if(result.status === 200){
                this.$refs.editorDialog.showNotification("Vorlagen gespeichert.")
            }
            this.emailBlocks = undefined
            this.loadEmailBlocks()
        },

        updateEmailBody(){
            if(this.requestedPersonData){
                let positions = this.getPositions();
                this.emailVariables.bereich.value = "<ul>"
                    for(let freizeitKey in positions.positions){
                        if(this.emailVariables[freizeitKey].value != ""){
                            this.emailVariables.bereich.value += "<li>"+"<b>"+freizeitKey+"</b> im Bereich <b>" + this.emailVariables[freizeitKey].value + "</b></li>"     
                        }
                    }
                this.emailVariables.bereich.value += "</ul>"
                this.loadEmailBlocks();
            }
        },
        changeStatusDate(oldStatus, newStatus){
            if(oldStatus != newStatus){
                return dayjs();
            }
            return undefined;
        },
        async sendEmail(){
            let attachmentsEmbedded = await this.getEmbeddedAttachments(this.email);
            let email = {
                from:this.senderEmail,
                to:this.emailVariables.email.value,
                // bcc:this.emailVariables.email.value,
                subject:this.betreff,
                html:attachmentsEmbedded.html,
                attachments:attachmentsEmbedded.attachments

            }
            if(this.emailVariables.status.value === 'Bestätigt'){
                let attachments = this.getAttachments();
                email["attachments"] = email["attachments"].concat(attachments);
            }
            let payload = {
                email:email,
                data:{}
            }
            this.$refs.editorDialog.setLoading(true);
            this.sending = true;
            let result = await this.$network.requestPost("/sendmail", payload);
            if (result.status === 200){
                this.$refs.editorDialog.setLoading(false);
                this.sending = false;
                this.$refs.editorDialog.showNotification("Email wurde erfolgreich gesendet.")

                let payload = {
                    "status_person":this.emailVariables.status.value,
                    "status_aenderung_datum":this.changeStatusDate(this.requestedPersonData.status_person, this.emailVariables.status.value)
                };
                if(this.emailVariables.statusSonderurlaub.value === 'Gewünscht'){
                // if(this.requestedPersonData.status_sonderurlaub === 'Gewünscht'){
                    payload["status_sonderurlaub"] = "Anlage 7a zugeschickt";
                }
                await this.$network.requestPatch("/items/"+this.peopleManager.collectionWithYear+"/"+this.id, payload);
                for(let item of this.peopleManager.peopleData){
                    if (item.id === this.id){
                        item.status_person = this.emailVariables.status.value;
                        item.status_aenderung_datum = payload.status_aenderung_datum;
                        item.status_sonderurlaub = payload.status_sonderurlaub;
                    }
                }
                this.hide();
            }else{
                this.$refs.editorDialog.setLoading(false);
                this.$refs.editorDialog.showNotification("Es ist ein Fehler beim versenden aufgetreten.","error","mdi-alert");
                this.sending = false;
                this.$refs.ErrorDialog.show(JSON.stringify(result.message))
            }
        },
        hide(){
            this.$refs.editorDialog.hide();
        },
        async show(){
            await this.loadCampVariables();
            this.requestedPersonData = undefined;
            this.$refs.editorDialog.show();
            this.emailVariables.status.value = '';
            this.email = "";
            this.betreff = "";
            this.edit = false;
        },
        getMaxEditorHeight(){
            return (Math.floor(window.innerHeight*0.48)).toString();
        },
        getPositions(){
            let positions = {};
            let nachWunsch = true;

            if(this.requestedPersonData.mitarbeiter_position){
                for(let freizeitKey in this.requestedPersonData.mitarbeiter_wunsch){
                    positions[freizeitKey] = "";
                    let wish1 = this.requestedPersonData.mitarbeiter_wunsch[freizeitKey].wish1;
                    let wish2 = this.requestedPersonData.mitarbeiter_wunsch[freizeitKey].wish2;
                    let finalPosition = this.requestedPersonData.mitarbeiter_position[freizeitKey].position;
                    if((wish1 !== finalPosition && wish2 !== finalPosition)){
                        nachWunsch = false;
                    }
                    if(finalPosition){
                        positions[freizeitKey] = finalPosition;
                    }
                }
            }
            return {positions:positions, nachWunsch:nachWunsch};
        },
        async loadEmailVariables(id){
            this.id = id;
            let bearbeiterResult = await this.$network.requestGet("/users/me");
            if(bearbeiterResult.status === 200){
                this.emailVariables.bearbeiter.value = bearbeiterResult.data.data.first_name + " " + bearbeiterResult.data.data.last_name;
                this.emailVariables.bearbeiter_telefon.value = bearbeiterResult.data.data.mobil_telefon;
                let result = await this.$network.requestGet("/items/"+this.peopleManager.collectionWithYear+"/"+id)
                if (result.status === 200){
                    this.requestedPersonData = result.data.data;
                    
                    let positions = this.getPositions();
                    this.emailVariables.vorname.value = this.requestedPersonData.vorname;
                    this.emailVariables.nachname.value = this.requestedPersonData.nachname;
                    this.emailVariables.email.value = this.requestedPersonData.email;
                    this.emailVariables.basicsAbsolviert.value = this.getBasics();
                    for(let freizeitKey in positions.positions){
                        this.emailVariables[freizeitKey] = {value:positions.positions[freizeitKey], disabled:true}
                    }
                    this.emailVariables.nachWunsch.value = positions.nachWunsch;
                    this.emailVariables.mitarbeiterWochenende.value = this.getMitarbeiterwochenende();
                    if(this.requestedPersonData.status_sonderurlaub === "Nicht gewünscht"){
                        this.emailVariables.statusSonderurlaub.value = this.requestedPersonData.status_sonderurlaub;
                    }else{
                        this.emailVariables.statusSonderurlaub.value = "Gewünscht"
                    }
                    this.updateEmailBody();
                }
            }
        },
        getMitarbeiterwochenende(){
            if(this.requestedPersonData.mitarbeiter_wochenende){
                return this.requestedPersonData.mitarbeiter_wochenende;
            }
            return false;
        },
        getBasics(){
            if(this.requestedPersonData.basics === "Ja"){
                return true;
            }
            return false;
        },
        async loadCampVariables(){
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString();
            url += "&fields=jahr";
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                this.camp = campResult.data.data[0];
            }
        },
        async loadEmailBlocks(){
            let year = this.peopleManager.dashboard.activeCampYear;
            let url = "/items/camp?filter[jahr][_eq]="+year.toString();
            url += "&fields=mitarbeiter_bestaetigung.*,mitarbeiter_bestaetigung.anhang.*"
            let campResult = await this.$network.requestGet(url)
            if(campResult.status === 200){
                let emailBlocks = campResult.data.data[0].mitarbeiter_bestaetigung;
                this.senderEmail = emailBlocks.absender;
                this.loadAttachments(emailBlocks.anhang)

                // Status Bestätigt
                if(this.emailVariables.status.value === "Bestätigt"){
                    this.betreff = this.replaceVariables(emailBlocks.betreff_bestaetigt);
                    this.email = this.replaceVariables(emailBlocks.block_1);
                    
                    if(this.emailVariables.nachWunsch.value){
                        this.email += this.replaceVariables(emailBlocks.block_2a);
                    }else{
                        this.email += this.replaceVariables(emailBlocks.block_2b);
                    }
                    if(!this.emailVariables.basicsAbsolviert.value){
                        this.email += this.replaceVariables(emailBlocks.block_3a);
                    }
                    if(this.emailVariables.mitarbeiterWochenende.value){
                        this.email += this.replaceVariables(emailBlocks.block_4a);
                    }else{
                        this.email += this.replaceVariables(emailBlocks.block_4b);
                    }

                    this.email += this.replaceVariables(emailBlocks.block_5);

                    if(this.emailVariables.statusSonderurlaub.value === "Gewünscht"){
                        this.email += this.replaceVariables(emailBlocks.block_6a);
                    }else{
                        this.email += this.replaceVariables(emailBlocks.block_6b);
                    }

                    this.email += this.replaceVariables(emailBlocks.block_7)
                // Status Warteliste
                }else if(this.emailVariables.status.value === "Warteliste"){
                    this.betreff = this.replaceVariables(emailBlocks.betreff_warteliste);
                    this.email = this.replaceVariables(emailBlocks.email_warteliste);
                // Status Abgesagt
                }else if(this.emailVariables.status.value === "Absage"){
                    this.betreff = this.replaceVariables(emailBlocks.betreff_abgesagt);
                    this.email = this.replaceVariables(emailBlocks.email_abgesagt);
                // Status Abgemeldet
                }else if(this.emailVariables.status.value === "Abgemeldet"){
                    this.betreff = this.replaceVariables(emailBlocks.betreff_abgemeldet);
                    this.email = this.replaceVariables(emailBlocks.email_abgemeldet);
                }
            }
        },
        replaceVariables(text){
            for(let key in this.requestedPersonData){
                let value = this.requestedPersonData[key];
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            for(let key in this.emailVariables){
                let value = this.emailVariables[key].value;
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            for(let key in this.camp){
                let value = this.camp[key];
                let find = "{{"+key+"}}";
                let re = new RegExp(find, 'g');
                text = text.replace(re, value);
            }
            return text;
        }
    }
}
</script>